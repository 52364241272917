import { Component, OnInit, Input } from '@angular/core';
import { User } from '@app/_models';
import { Subscription } from 'rxjs';
import { AuthenticationService, UserService } from '@app/_services';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.css']
})
export class Step2Component implements OnInit {
  

  currentUser: User;

  @Input()
  coupons;

  @Input()
  new_campaign;

  @Input()
  client_data;

  currentUserSubscription: Subscription;
  


    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService
    ) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
    }

    ngOnInit(): void {
    }

    

}
