import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cuponesFilter',
    pure: false
})
export class CuponsFilter implements PipeTransform {
    transform(cupones: any[], locales: any): any {
        if (!cupones || !locales || locales.length==0) {
            return [];
        }
        return cupones.filter( cup => locales.filter(l=>l.selected).map(l => l.id).indexOf(cup.local_id)>-1 );
    }
}