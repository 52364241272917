import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CouponsService } from '@app/_services/coupons.service';
import { NewCampaign } from '@app/_models/new_campaign';
import { CampaignsService } from '@app/_services/campaigns.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';


@Component({
  selector: 'app-mailing-dashboard',
  templateUrl: './mailing-dashboard.component.html',
  styleUrls: ['./mailing-dashboard.component.css']
})
export class MailingDashboardComponent implements OnInit {


  constructor(private couponsServie: CouponsService, private campaignService: CampaignsService, private titleService: Title, private router: Router) { }

  showMenu = false;

  loading = true;

  auth_new_campaign = false;
  auth_reports = false;

  locales = [];

  client_data = {
    credits: -1,
    name: "",
    client_id: -1,
    web: "",
    whatsapp: "",
    telefono: "",
    local_img_path: "",
    locales: [
    ]
  };

  coupons = [];

  new_campaign: NewCampaign = new NewCampaign();

  campaigns = [{}];

  mode = 0;

  alax = false;

  ngOnInit() {
    try{
      if(JSON.parse(localStorage.getItem('permissions')).indexOf('MAIL')>-1) this.auth_new_campaign = true; 
    }
    catch(ex){ console.log("NOTMAIL", ex)}
    try{
      if(JSON.parse(localStorage.getItem('permissions')).indexOf('REPORTS')>-1) this.auth_reports = true; 
    }
    catch(ex){}
    
    this.client_data.client_id = parseInt(localStorage.getItem('client_id'));
    this.client_data.credits = parseInt(localStorage.getItem('client_credits'));
    this.client_data.name = localStorage.getItem('client_name');
    this.client_data.local_img_path = "https://cuponeala.com/images/clientes/"+localStorage.getItem('client_image'),
    this.titleService.setTitle("Alax");
    this.new_campaign.name = "Nueva campaña";
    this.new_campaign.subject = "Tus cupones están listos";
    this.new_campaign.from = this.client_data.name;
    this.new_campaign.filters = [ { name: "activity", value: 0}];

    this.new_campaign.since = new Date();
    this.new_campaign.till = new Date();

    if(!this.client_data.client_id || this.client_data.client_id<0){
      localStorage.clear();
      this.router.navigate(['/login']);
    }
    this.getCoupons();
    this.getCampaigns();
    this.getLocales();

    // setInterval( () => { this.getCampaigns()} , 600000);

    var baseUrl = "https://cuponeala.com";
    try {
      if (window){
        const parsedUrl = new URL(window.location.href);
        baseUrl = parsedUrl.origin;
      } 
    }catch(err){}

    if (baseUrl.includes('alax.tech')) {
      this.alax = true;
    } else {
      this.alax = false;
    }
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }


  getCoupons() {
    this.couponsServie.getCoupons({ cliente: this.client_data.client_id }).pipe().subscribe(response => {
      this.coupons = response.coupons;
      // console.log("Cupones", response.coupons);
    },
    error =>{
      localStorage.clear();
      this.router.navigate(['/login']);
    }
    );
  }

  getCampaigns() {
    this.campaignService.getCampaigns().pipe().subscribe(response => {
      if(response.credits){
        this.client_data.credits = response.credits;
        localStorage.setItem("client_credits", response.credits);
      } 
      this.campaigns = response.campaigns;
      // console.log(this.campaigns);
    },
      error =>{
        localStorage.clear();
        this.router.navigate(['/login']);
      }    );
  }

  getLocales(){
    this.couponsServie.getLocales(this.client_data.client_id).pipe().subscribe( response =>{
      console.log(response);
      this.client_data.locales = response.shops; 
      for(let l of this.client_data.locales){
        l.selected = true;
      }
      this.loading = false;
    },
    error =>{
      localStorage.clear();
      this.router.navigate(['/login']);
    });
  }


  
}
