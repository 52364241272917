import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AccountService {
    constructor(private http: HttpClient) { }

    validateAccount(token : String) {
        return this.http.post<any>(`${environment.apiUrl}/api/verify_account`,{token : token});
    }
    resetPwd(token : String, password: String) {
        return this.http.post<any>(`${environment.apiUrl}/api/update_password`,{token : token, password: password});
    }
}