import { Component, OnInit } from '@angular/core';
import { AlertService, AccountService } from '@app/_services';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { isDifferent } from '@angular/core/src/render3/util';

@Component({
  selector: 'app-nueva-clave',
  templateUrl: './nueva-clave.component.html',
  styleUrls: ['./nueva-clave.component.css']
})
export class NuevaClaveComponent implements OnInit {
  changePasswordForm: FormGroup;
  submitted = false;
  loading = false;
  token : String = "";
  redir: string;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private alertService: AlertService) {

      if(this.route.snapshot.queryParams['token'] ){
        this.token = this.route.snapshot.queryParams['token'];
      }
      if(this.route.snapshot.queryParams['redir'] ){
        this.redir = this.route.snapshot.queryParams['redir'];
      }

     }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      rePassword: ['',[Validators.required, Validators.minLength(8), Validators.maxLength(30), Validators.pattern('^[A-zñÑ.0-9_+!-%&$]{1,30}$')]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(30), Validators.pattern('^[A-zñÑ.0-9_+!-%&$]{1,30}$')]]
  });
  }
  get f() { return this.changePasswordForm.controls; }
  
  get g() { return this.changePasswordForm.value ; }

  get isDif(){
    return this.changePasswordForm.value.password != this.changePasswordForm.value.rePassword;

  }
  changePwd() {
      this.submitted = true;
      if (this.changePasswordForm.invalid ||  this.isDif ) {
        if (this.isDif){
          this.alertService.error("Las contraseñas no coinciden");
        }
          return;
      }

      this.loading = true;
      this.accountService.resetPwd(this.token , this.changePasswordForm.value.password)
          .pipe(first())
          .subscribe(
            data => {
              this.loading = false;
              if (data.result == 1){
                 this.alertService.success("Contraseña actualizada con éxito");
             setTimeout(() => 
                  {
                    if (this.redir) {
                      window.location.href = this.redir;
                    } else {
                      window.location.href = 'https://www.cuponeala.com';
                    }
                  },
                  5000);
              }else{
                this.alertService.error("Token inválido - Solicite que le vuelvan a enviar el mail para modificar la contraseña");
              }
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            });
  }
}
