﻿import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './_components/home';
import { LoginComponent } from './_components/login';
import { RegisterComponent } from './_components/register';
import { AuthGuard } from './_guards';
import { ActivarComponent } from './_components/activar/activar.component';
import { NuevaClaveComponent } from './_components/nueva-clave/nueva-clave.component';
import { MailingDashboardComponent } from './_components/mailing-dashboard/mailing-dashboard.component';
import { NewCampaignComponent } from './_components/new-campaign/new-campaign.component';
import { CampaignsComponent } from './_components/campaigns/campaigns.component';
import { DesubscribeComponent } from './_components/desubscribe/desubscribe.component';

const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'activar', component: ActivarComponent },
    { path: 'nueva_clave', component: NuevaClaveComponent },
    { path: 'desubscribe', component: DesubscribeComponent },
    { path: 'mailing-dashboard/newCampaing', component: NewCampaignComponent, canActivate: [AuthGuard]},
    { path: 'mailing-dashboard/campaigns', component: CampaignsComponent, canActivate: [AuthGuard]},
    { path: 'mailing-dashboard', component: MailingDashboardComponent, canActivate: [AuthGuard]},
    { path: 'mailing-dashboard/*', component: MailingDashboardComponent, canActivate: [AuthGuard]},
    { path: '', component: MailingDashboardComponent },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);