import { NewCampaignFilter } from "./new_campaign_filter";

export class NewCampaign{
    name: String;
    // text: String;
    subject: string;
    image_file_name: String;
    filters: NewCampaignFilter[];
    coupons: any[];
    from: string;

    text1: string;
    text2: string;
    since: Date =  new Date();
    till: Date = new Date();

}

