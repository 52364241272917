import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { NewCampaign } from '@app/_models/new_campaign';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor(private http: HttpClient) { }

  getCampaigns(){
    return this.http.get<any>(`${environment.apiUrl}/api/campaigns`);
  }

  createCampaign(campaign: NewCampaign){
    return this.http.post<any>(`${environment.apiUrl}/api/campaigns`, campaign);
  }

  getUserCount(filter){
    return this.http.post<any>(`${environment.apiUrl}/api/campaign_estimation`, filter);
  }

}

