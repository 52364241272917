import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent implements OnInit {

  alax = false;
  constructor() { }

  @Input()
  hideButtons = false;

  @Input()
  context;

  ngOnInit() {
    var baseUrl = "https://cuponeala.com";
    try {
      if (window){
        const parsedUrl = new URL(window.location.href);
        baseUrl = parsedUrl.origin;
      } 
    }catch(err){}

    if (baseUrl.includes('alax.tech')) {
      this.alax = true;
    } else {
      this.alax = false;
    }
  }

  toggle(){
    this.context.showMenu = !this.context.showMenu;

  }
}
