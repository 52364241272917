import { Component, OnInit } from '@angular/core';
import { AlertService, AccountService } from '@app/_services';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-activar',
  templateUrl: './activar.component.html',
  styleUrls: ['./activar.component.css']
})
export class ActivarComponent implements OnInit {
  token : String;
  redir: string;
  loading : Boolean = false;
  constructor(
    private accoutService: AccountService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
    ) { 
      if(this.route.snapshot.queryParams['token'] ){
        this.token = this.route.snapshot.queryParams['token'];
      }
      if(this.route.snapshot.queryParams['redir'] ){
        this.redir = this.route.snapshot.queryParams['redir'];
      }


    }

  ngOnInit() {
  }

  activate(){
    this.loading = true;
    this.accoutService.validateAccount(this.token).pipe(first())
    .subscribe(
        data => {
          if (data.result == 1){
            this.alertService.success("Felicitaciones! Tu cuenta ya está activa.");
            setTimeout(() => 
            {
              if (this.redir) {
                window.location.href = this.redir;
              } else {
                window.location.href = 'https://www.cuponeala.com';
              }
            },
            5000);
            
          }else{
            this.alertService.error("Token inválido");
          }
        },
        error => {
            this.alertService.error(error);
            this.loading = false;
        });

  }

}
