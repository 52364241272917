import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Filters } from '@app/_models/filters';

@Injectable({ providedIn: 'root' })
export class CouponsService {
    constructor(private http: HttpClient) { }

    getCoupons(filters) {
        return this.http.get<any>(`${environment.apiUrl}/api/coupons`, {params: filters});
    }

    getLocales(id_cliente){
        return this.http.get<any>(`${environment.apiUrl}/api/shops`, {params: {cliente: id_cliente}});
    }
}