import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-user-data-container',
  templateUrl: './user-data-container.component.html',
  styleUrls: ['./user-data-container.component.css']
})
export class UserDataContainerComponent implements OnInit {

  constructor() { }

  @Input()
  client_data;

  ngOnInit() {
  }

}
