import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  alax = false;
  constructor() { }

  ngOnInit() {
    var baseUrl = "https://cuponeala.com";
    try {
      if (window){
        const parsedUrl = new URL(window.location.href);
        baseUrl = parsedUrl.origin;
      } 
    }catch(err){}

    if (baseUrl.includes('alax.tech')) {
      this.alax = true;
    } else {
      this.alax = false;
    }
  }

}
