import { Component, OnInit, Input } from '@angular/core';
import { NewCampaign } from '@app/_models/new_campaign';
import { NewCampaignFilter } from '@app/_models/new_campaign_filter';
import { CampaignsService } from '@app/_services/campaigns.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MailingDashboardComponent } from '@app/_components/mailing-dashboard/mailing-dashboard.component';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.css']
})
export class Step3Component implements OnInit {

  constructor(private campaignService: CampaignsService, private router: Router) { }

  @Input()
  new_campaign: NewCampaign;

  @Input()
  coupons: [any];

  @Input()
  client_data;

  @Input('context') context: MailingDashboardComponent;

  confirmating = false;
  estimated_emails = -1;
  campaign_name = "";

  showPreview = false;

  loading = true;

  alax = false;

  ngOnInit() {
    this.new_campaign.coupons = [];
    this.coupons.forEach(c => {
      if (c.selected == true) this.new_campaign.coupons.push({ id: c.id, local_id: c.local_id });
    })

    this.campaignService.getUserCount({ filters: this.new_campaign.filters }).pipe().subscribe(response => {
      if (response.logged == 1) {
        if (response.result == 1) {
          this.estimated_emails = response.estimated_emails;
          this.loading = false;
        }
      }
    })

    var baseUrl = "https://cuponeala.com";
    try {
      if (window){
        const parsedUrl = new URL(window.location.href);
        baseUrl = parsedUrl.origin;
      } 
    }catch(err){}

    if (baseUrl.includes('alax.tech')) {
      this.alax = true;
    } else {
      this.alax = false;
    }
  }

  confirm() {
    this.loading = true;
    this.confirmating = false;
    if (this.validateNewCampaignData()) {
      var body : any= this.new_campaign;
      let pipe = new DatePipe('en-US');
      for(let a of body.filters){
        if(a.name == 'activity'){
          a.since = pipe.transform(body.since, 'yyyy-MM-dd');
          a.till = pipe.transform(body.till, 'yyyy-MM-dd');
        }
      }
      body.since = undefined;
      body.till = undefined;
      this.campaignService.createCampaign(this.new_campaign).subscribe(response => {
        this.loading = false;
        this.confirmating = false;
        if (response.logged == 0) {
          this.router.navigate(['/login']);
          return;
        }
        if (response.result == 1) {
          if (response.credits) {
            this.client_data.credits = response.credits;
          }
          this.context.mode = 2;
          this.context.getCampaigns();
        }
      },
      error =>{
        this.loading = false;
      })
    }
  }

  send() {
    if(this.validateNewCampaignData()){
      this.confirmating= true;
    }
    // this.result = JSON.stringify(this.new_campaign);
  }

  errors: string;
  validateNewCampaignData() {
    this.errors = "";
    if (this.new_campaign.coupons.length < 1) {
      this.errors = "Debes seleccionar al menos un cupón.";
      return false;
    }
    if (this.client_data.credits < this.estimated_emails) {
      this.errors = "Créditos insuficientes";
      return false;
    }


    return true;
  }
}
