import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class DesubscribeService {

  constructor(private http: HttpClient) {}
   
    desubscribe(params:any) {
        return this.http.get<any>(`${environment.apiUrl}/api/desubscribe`, {params});
            
    }

}