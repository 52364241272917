import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.css']
})
export class CampaignsComponent implements OnInit {

  constructor() { }

  @Input()
  campaigns;

  text = "";

  ngOnInit() {
    this.text = JSON.stringify(this.campaigns);
  }

}
