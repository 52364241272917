﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService } from '@app/_services';

@Component({
    templateUrl: 'login.component.html',
    selector: 'app-login',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    alax = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/mailing-dashboard']);
        }
    }

    ngOnInit() {
        localStorage.clear();
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

        var baseUrl = "https://cuponeala.com";
        try {
            if (window){
                const parsedUrl = new URL(window.location.href);
                baseUrl = parsedUrl.origin;
            } 
        }catch(err){}

        if (baseUrl.includes('alax.tech')) {
            this.alax = true;
        } else {
            this.alax = false;
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe()
            .subscribe(
                data => {
                    this.loading = false;
                    if(data.logged==1){
                        this.router.navigate(["/mailing-dashboard"]);
                        localStorage.setItem('client_id', data.cliente_id);
                        localStorage.setItem('client_name', data.razon_social);
                        localStorage.setItem('client_credits', data.credits);
                        localStorage.setItem('client_image', data.imagen);
                        localStorage.setItem('token', data.token);
                        localStorage.setItem('web', data.web);
                        localStorage.setItem('telefono', data.telefono_cod_area + data.telefono);
                        localStorage.setItem('permissions', JSON.stringify(data.permissions));
                    }
                    if(data.errors){
                        this.alertService.error(data.errors[0].msg);
                        return;
                    }
                    else{
                        
                    }
                    
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });

    }
}
