import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'localesFilter',
    pure: false
})
export class LocalFilter implements PipeTransform {
    transform(items: any[], filter: string): any {
        if (!items || !filter || filter.length==0) {
            return items;
        }
        return items.filter( loc => loc.shop_name.toLowerCase().includes(filter.toLocaleLowerCase()) );
    }
}