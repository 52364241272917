import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DesubscribeService } from '@app/_services/desubscribe.service';

@Component({
  selector: 'app-desubscribe',
  templateUrl: './desubscribe.component.html',
  styleUrls: ['./desubscribe.component.css']
})
export class DesubscribeComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private desubscribeService: DesubscribeService, private router: Router) { }


  token;
  email;
  cliente_id;

  msg = "";

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe( d => {
      if(d.token) this.token = d.token;
      if(d.email) this.email = d.email;
      if(d.cliente_id) this.cliente_id = d.cliente_id;
      if(!this.email || !this.token || !this.cliente_id) this.router.navigate(['/login']);
    })
  }

  desubscribe() {
    this.desubscribeService.desubscribe({
      token: this.token,
      email: this.email,
      cliente_id: this.cliente_id
    }).subscribe(
      r => {
        if (r.result == 1) {
          this.msg = "Desuscripción exitosa. No recibirás más emails.";
        } else {
          this.msg = r.errors[0].msg;
        }
      },
      e => {
        this.msg = "Hubo un error al intentar desuscribirse. Intente nuevamente más tarde.";
      }
    )
  }
}
