﻿import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule }    from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppComponent }  from './app.component';
import { routing }        from './app.routing';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './_components/home';
import { LoginComponent } from './_components/login';
import { RegisterComponent } from './_components/register';
import { ActivarComponent } from './_components/activar/activar.component';
import { NuevaClaveComponent } from './_components/nueva-clave/nueva-clave.component';;
import { MailingDashboardComponent } from './_components/mailing-dashboard/mailing-dashboard.component';
import { NewCampaignComponent } from './_components/new-campaign/new-campaign.component';
import { CampaignsComponent } from './_components/campaigns/campaigns.component';
import { Step1Component } from './_components/new-campaign/step1/step1.component';
import { Step2Component } from './_components/new-campaign/step2/step2.component';
import { Step3Component } from './_components/new-campaign/step3/step3.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { CouponsComponent } from './_components/coupons/coupons.component';
import { UserDataContainerComponent } from './_components/user-data-container/user-data-container.component';
import { FooterComponent } from './_components/footer/footer.component';
import { NavHeaderComponent } from './_components/nav-header/nav-header.component'
import { AlertComponent } from './_components/alert/alert.component';
import { LocalFilter } from './_filters/local_filter';;
import { MailPreviewComponent } from './_components/mail-preview/mail-preview.component'
import { CuponsFilter } from './_filters/coupons_filter';;
import { DesubscribeComponent } from './_components/desubscribe/desubscribe.component'

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        routing,
        AngularFontAwesomeModule,
        FormsModule
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        ActivarComponent ,
        NuevaClaveComponent ,
        MailingDashboardComponent ,
        NewCampaignComponent,
        CampaignsComponent,
        Step3Component,
        Step2Component,
        Step1Component,
        CouponsComponent ,
        UserDataContainerComponent ,
        FooterComponent ,
        NavHeaderComponent,
        LocalFilter,
        MailPreviewComponent,
        CuponsFilter
,
        DesubscribeComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // provider used to create fake backend
        fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }