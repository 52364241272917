import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NewCampaign } from '@app/_models/new_campaign';
import { NewCampaignFilter } from '@app/_models/new_campaign_filter';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.css']
})
export class Step1Component implements OnInit {

  @Input()
  new_campaign: NewCampaign;

  @Input()
  client_data;

  selectedActivityTime: number = 0;

  localFilter = "";

  images: string[]= [
    "banner1.png",
    "banner2.png",
    "banner3.png"
  ]

  texts1: string[] = [
    "No te pierdas los nuevos cupones, activalos sólo con tu DNI. A partir de ahora... tenés el descuento fácil!", 
    "¡Queremos que seas el primero en enterarte! Sumamos nuevos cupones de descuento:", 
    "¡Volvimos con más descuentos! Aprovechá ahora los nuevos cupones que tenemos para vos.", 
    "¡Tenés el descuento fácil! Activá estos cupones pensados para vos sólo con tu DNI.",
    "¡Descuentos Sorpresa! Aprovechá los cupones de último momento que tenemos a continuación!"
  ];

  texts2: string[]= [
    "¡Activá hoy tu cupón y disfrutá de los beneficios!", 
    "¡Activá tu cupón y aprovechá los mejores descuentos!", 
    "¡No te los pierdas! Bajate la APP de Cuponeala y encontrá nuestros cupones.", 
    "Con Cuponeala, tenés el descuento fácil! Descargate la APP y no te pierdas ninguna novedad.", 
    "Activá tu cupón ahora. Encontranos en la App de Cuponeala, conseguir descuentos es rápido, fácil y gratis!"
  ];

  

  setNewCampaignActivityFilterValue( value: number){
    this.selectedActivityTime = value;
    if(!this.new_campaign.filters) this.new_campaign.filters = [];
    else{
      this.new_campaign.filters = this.new_campaign.filters.filter( c => c.name && c.name!="activity");
    }
    let f = new NewCampaignFilter();
    f.name = "activity";
    f.value = value;
    this.new_campaign.filters.push( f );
  }
  
  constructor() { }

  ngOnInit() {
    if(!this.new_campaign.image_file_name) this.new_campaign.image_file_name = this.images[0];
    if(this.new_campaign && this.new_campaign.filters){
        this.new_campaign.filters.forEach( f =>{
          if(f.name == "activity"){
            this.selectedActivityTime = f.value;
          }
        })
    }

    if(!this.new_campaign.text1) this.new_campaign.text1 = this.texts1[0];
    if(!this.new_campaign.text2) this.new_campaign.text2 = this.texts2[0];
    if(!this.new_campaign.image_file_name) this.new_campaign.image_file_name = this.images[0];

  }

  allChecked = false;
  selectAllLocales()
  {
    this.allChecked = !this.allChecked;
    let locs : any[] = this.client_data.locales;
    locs.forEach(l => {
      l.selected = this.allChecked;
    })
    this.localFilter = "";
  }

  showHint(){
    var popup = document.getElementById("myPopup");
    popup.classList.toggle("show");
    var popup2 = document.getElementById("myPopup2");
    popup2.classList.toggle("show");
  }

}
