import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mail-preview',
  templateUrl: './mail-preview.component.html',
  styleUrls: ['./mail-preview.component.css']
})
export class MailPreviewComponent implements OnInit {

  constructor() { }

  @Input()
  client_data : any;

  @Input()
  coupons: any;

  @Input()
  new_campaign: any;

  selectedCoupons = [];

  ngOnInit() {
    this.coupons.forEach( c => {
      if(c.selected) this.selectedCoupons.push(c);
    });
  }

}
