import { Component, OnInit, Input } from '@angular/core';
import { NewCampaign } from '@app/_models/new_campaign';
import { NewCampaignFilter } from '@app/_models/new_campaign_filter';

@Component({
  selector: 'app-new-campaign',
  templateUrl: './new-campaign.component.html',
  styleUrls: ['./new-campaign.component.css']
})
export class NewCampaignComponent implements OnInit {

  constructor() { }

  step = 1;
  totalSteps =3;
  error = "";

  @Input()
  coupons;

  @Input()
  new_campaign : NewCampaign;

  @Input()
  client_data;

  @Input('context') context;

  ngOnInit() {
  }

  nextStep(){
    this.error = "";
    if(this.step==1 && !this.validateStep1()) return;
    if(this.step==2 && !this.validateStep2()) return;
    if(this.step==3 && !this.validateStep3()) return;

    if(this.step<this.totalSteps)this.step++;
    if(this.step === 2){
      this.createLocalesFilter();
    }
  }

  previousStep(){
    if(this.step>1) this.step--;
  }

  createLocalesFilter(){
    if(!this.new_campaign.filters) this.new_campaign.filters = [];
    else{
      this.new_campaign.filters = this.new_campaign.filters.filter( c => c.name && c.name!="shops");
    }

    let loc = [];
    this.client_data.locales.forEach(l => {
      if(l.selected){
        loc.push(l.id);
      }
    });
    if(loc.length>0){
      let loc_filter = new NewCampaignFilter();
      loc_filter.name = "shops";
      loc_filter.value = loc;
      this.new_campaign.filters.push(loc_filter);
    }
  }

  validateStep1(){
    if(this.new_campaign.name.length<5){
      this.error = "Nombre de campaña inválido. Debe contener al menos 5 caracteres";
      return false;
    }
    if(this.new_campaign.subject.length<5){
      this.error = "Asunto de email inválido. Debe contener al menos 5 caracteres";
      return false;
    }
    
    if(this.client_data.locales.filter(l => l.selected).length<1){
      this.error = "Debes seleccionar al menos 1 local";
      return false;
    }

    for(let c of this.coupons){
      if(this.client_data.locales.filter(l=>l.selected).map(l=>l.id).indexOf(c.local_id)<0){
        c.selected = false;
      }
    }
    return true;
  }
  validateStep2(){
    if(this.coupons.filter( c => c.selected).length<1){
      this.error = "Debe seleccionar al menos un cupón";
      return false;
    }
    return true;
  }
  validateStep3(){
    return true;
  }


}
