import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.css']
})
export class CouponsComponent implements OnInit {

@Input()
data;

@Input()
readOnly = false;

  constructor() { }

  ngOnInit() {
    // this.data.image = "https://cuponeala.com/images/cupones/613.jpg";
  }

  toggleSelected(){
    if(!this.data.selected) this.data.selected = false;
    this.data.selected = !this.data.selected;
  }

}
